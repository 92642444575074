import React, { useState } from "react";
import { loginBack } from "helper/constant";
import ForgotIcon from "../../../assets/images/forgotpassword.svg";
import NavigationActions from "redux/navigation/actions";
import enhancer from "./enhancer/ForgotPasswordEnhancer";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { forgotPassword } from "services/adminServices";
import Loader from "components/Loader";

const { success, error, fetching } = NavigationActions;

const ForgotPassword = props => {
  // const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    success,
    error,
    fetching,
    isValid,
    handleSubmit
  } = props;

  const loginContainer = {
    backgroundImage: `url(${loginBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    handleSubmit();
    if (isValid) {
      fetching();
      setLoading(true);
      await forgotPassword(values).then(data => {
        if (data.success) {
          success(data.message);
          setLoading(false);
        } else {
          error(data.message);
          setLoading(false);
        }
      });
    }
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div className="form-container">
        <div className="login-icon">
          <img src={ForgotIcon} alt="icon" height="100px" />
        </div>
        <div className="login-title">Forgot Password ?</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Provide your e-mail address to reset your password
        </div>
        <form className="pa-24" onSubmit={handleForgotPassword}>
          <div className="form-group">
            <input
              type="email"
              className="form-control react-form-input"
              id="email"
              aria-describedby="emailHelp"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="Enter email"
            />
            <Error field="email" />
          </div>
          <button
            type="submit"
            className="btn form-button siteenterbutton"
            disabled={isValid ? false : true}
          >
            Get Link
          </button>
        </form>
      </div>
      {loading && <Loader />}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    // isFetching: state.navigation.isFetching
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, { success, error, fetching })
)(ForgotPassword);
