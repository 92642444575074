import { connect } from "react-redux";
import React, { useState } from "react";
import { Button, ModalHeader, ModalBody } from "reactstrap";
import * as Yup from "yup";

import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { useEffect } from "react";
import Loader from "components/Loader";
import { addVenue, editVenue } from "services/venueServices";
import { useFormik } from "formik";

const { success, error } = NavigationActions;
const initialValues = {
  name: "",
  noOfRooms: 0,
  address1: "",
  address2: "",
  city: "",
  state: "",
  pincode: "",
  status: false
};
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter Name"),
  noOfRooms: Yup.number(),
  address1: Yup.string().required("Please enter address line 1"),
  address2: Yup.string(),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please Enter Email"),
  phone: Yup.string()
    .matches(/^\d+$/, "Please Enter valid Phone Number")
    .length(10, "Please Enter valid phone number")
    .required("Please Enter Phone Number"),
  city: Yup.string()
    .matches(/^[A-Z a-z]+$/, "Please enter valid city")
    .required("Please enter city"),
  state: Yup.string(/^[A-Z a-z]+$/, "Please enter valid state").required(
    "Please enter state"
  ),
  pincode: Yup.string()
    .matches(/^[0-9]+$/, "Please enter valid pincode")
    .typeError("Please enter valid pin")
    .min(4)
    .max(10)
    .required("Please enter pincode")
});
const AddVenueModal = props => {
  const { isEdit, editData, onClose, toggleRefresh, token } = props;
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async submittedValues => {
      try {
        setLoading(true);
        if (isEdit) {
          const editRes = await editVenue(
            token,
            submittedValues._id,
            submittedValues
          );
          if (editRes.success) {
            success(editRes.message);
            toggleRefresh(true);
            onClose();
          } else {
            error(editRes.message);
          }
        } else {
          const addRes = await addVenue(token, submittedValues);
          if (addRes.success) {
            success(addRes.message);
            toggleRefresh(true);
            onClose();
          } else {
            error(addRes.message);
          }
        }
      } catch (error) {
        console.log("Error while submitting venue data", error);
      } finally {
        setLoading(false);
      }
    }
  });

  const { values, handleChange, handleBlur, setValues } = formik;

  const Error = props => {
    const field1 = props.field;
    if (
      (formik.errors[field1] && formik.touched[field1]) ||
      formik.submitCount > 0
    ) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {formik.errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  useEffect(() => {
    isEdit && setValues({ ...editData });
  }, [isEdit, editData]);

  console.log("formik.errors", formik.errors);

  return (
    <>
      <ModalHeader toggle={() => onClose()}>
        {isEdit ? "Edit" : "Add"} Venue
      </ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label>
              Name <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="name"
              onChange={handleChange}
              value={values.name}
              onBlur={handleBlur}
              placeholder="Enter venue name"
            />
            <Error field="name" />
          </div>

          <div className="form-group">
            <label>
              Email <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="email"
              onChange={handleChange}
              value={values.email}
              onBlur={handleBlur}
              placeholder="Venue email"
              // disabled={isEdit ? true : false}
            />
            <Error field="email" />
          </div>
          <div className="form-group">
            <label>
              Phone <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="phone"
              onChange={handleChange}
              value={values.phone}
              onBlur={handleBlur}
              placeholder="Venue phone"
            />
            <Error field="phone" />
          </div>

          <div className="form-group">
            <label>
              Address Line 1 <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="address1"
              onChange={handleChange}
              value={values.address1}
              onBlur={handleBlur}
              placeholder="Address Line 1"
            />
            <Error field="address1" />
          </div>
          <div className="form-group">
            <label>Address Line 2</label>
            <input
              type="text"
              className="form-control react-form-input"
              id="address2"
              onChange={handleChange}
              value={values.address2}
              onBlur={handleBlur}
              placeholder="Enter address line 2"
            />
            <Error field="address2" />
          </div>

          <div className="form-group">
            <label>
              City <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="city"
              onChange={handleChange}
              value={values.city}
              onBlur={handleBlur}
              placeholder="Enter city"
            />
            <Error field="city" />
          </div>
          <div className="form-group">
            <label>
              State <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="state"
              onChange={handleChange}
              value={values.state}
              onBlur={handleBlur}
              placeholder="Enter state"
            />
            <Error field="state" />
          </div>
          <div className="form-group">
            <label>
              Pincode <span className="red">*</span>
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              id="pincode"
              onChange={handleChange}
              value={values.pincode}
              onBlur={handleBlur}
              placeholder="Enter pincode"
            />
            <Error field="pincode" />
          </div>

          <div className="row">
            <div className="col-5 offset-2 offset-sm-4 col-sm-4">
              <Button
                type="submit"
                className="btn form-button modaladdbutton"
                disabled={loading || formik.isSubmitting}
              >
                {isEdit ? "Update" : "Add"}
                {/* {console.log(isValid,values,"isValid")} */}
              </Button>
            </div>
            <div className="col-5 col-sm-4">
              <Button
                onClick={onClose}
                className="btn form-button modalcancelbutton"
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </ModalBody>
      {loading ? <Loader /> : ""}
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(connect(mapStateToProps, { success, error }))(
  AddVenueModal
);
