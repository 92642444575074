import { Pagination } from "@mui/material";
import classNames from "classnames";
import Loader from "components/Loader";
import ReactTableWrapper from "components/reacttable/reacttbl.style";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import { compose } from "redux";
import NavigationActions from "redux/navigation/actions";
import { getStaffAllData } from "services/staffServices";
import { getServiceAllData } from "services/serviceServices";
import { getallRooms } from "services/roomServices";
import Select from "react-select";
// import { history } from 'redux/store';
import { getallrequestData } from "services/requestServices";
import { getAllVenues } from "services/venueServices";

const { success, error } = NavigationActions;
const RequestList = props => {
  const { token, history, values, setFieldValue } = props;
  // console.log(history, "check21");

  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCng, setPageCng] = useState(1);
  const [refresh, toggleRefresh] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [serviceData, setServiceData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [roomnameFilter, setRoomNameFilter] = useState("");
  const [venueFilter, setVenueFilter] = useState("");
  const [staffnameFilter, setStaffNameFilter] = useState("");
  const [servicenameFilter, setServiceNameFliter] = useState("");
  const [venueData, setVenueData] = useState([]);

  const HeaderComponent = props => {
    let classes = {
      "my-2": true,
      "mx-2": true,
      "-sort-asc": props.isSortedDesc === false,
      "-sort-desc": props.isSortedDesc === true
    };
    return <div className={classNames(classes)}>{props.title}</div>;
  };

  const getRequestData = useCallback(async () => {
    setLoading(true);
    const data = {
      limit: 10,
      page: pageCng,
      search: search,
      room_id: roomnameFilter,
      response_by: staffnameFilter,
      service_id: servicenameFilter,
      venue_data: venueFilter,
      is_escalat: history.location.pathname === "/request" ? false : true
    };

    await getallrequestData(token, data).then(val => {
      // console.log(val, "checkval43");
      if (val.success) {
        setRequestData(val.data.docs);
        setPageCount(val.data.totalPages);
        setLoading(false);
        console.log(val.data.docs, "check64");
        toggleRefresh(false);
      } else {
        setLoading(false);
      }
    });
  }, [
    pageCng,
    search,
    roomnameFilter,
    staffnameFilter,
    servicenameFilter,
    venueFilter
  ]);

  const getStaffData = async () => {
    await getStaffAllData(token)
      .then(res => {
        // console.log(res, "res");
        let staffArr = res.data.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setStaffData(staffArr);
      })
      .catch(err => {
        error(err.message);
      });
  };

  const getServiceData = async () => {
    await getServiceAllData(token)
      .then(res => {
        // console.log(res, "checkres");
        let serviceArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setServiceData(serviceArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  const getAllRoomData = async () => {
    await getallRooms(token)
      .then(res => {
        // console.log(res, "checkres");
        let roomArr = res.data?.map((val, i) => {
          return { value: val._id, label: val.name };
        });
        setRoomData(roomArr);
      })
      .catch(err => {
        error(err.message);
      });
  };
  const getVenuesData = async () => {
    await getAllVenues(token)
      .then(res => {
        let venueOptions = res.data.map(venue => {
          return { value: venue._id, label: venue.name };
        });
        setVenueData(venueOptions);
      })
      .catch(err => {
        error(err.message);
      });
  };
  useEffect(() => {
    getStaffData();
    getVenuesData();
    getServiceData();
    getAllRoomData();
  }, []);

  // useEffect(() => {
  //   getStaffData();

  // }, []);

  useEffect(() => {
    refresh && getRequestData();
  }, [
    refresh,
    roomnameFilter,
    staffnameFilter,
    servicenameFilter,
    venueFilter
  ]);

  const columns = useMemo(
    () => [
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="No"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "no",
        disableFilters: true,
        accessor: "no",
        Cell: tableInstance => (
          <span>{tableInstance.row.index + 1 + (pageCng - 1) * 10}</span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Venue Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Venue Name",
        disableFilters: true,
        accessor: "venue_data.name",
        Cell: tableInstance => (
          <span className={"text-capitalize"}>
            {tableInstance.row.original?.venue_data === null
              ? "-"
              : tableInstance.row.original?.venue_data?.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Room Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Room Names",
        disableFilters: true,
        accessor: "room_id.name",
        Cell: tableInstance => (
          <span
            className={
              tableInstance.row.original.room_id !== null
                ? tableInstance.row.original.room_id.status === false ||
                  tableInstance.row.original.room_id.is_delete === true
                  ? "disablefiled text-capitalize"
                  : "text-capitalize"
                : ""
            }
          >
            {tableInstance.row.original.room_id === null
              ? "-"
              : tableInstance.row.original.room_id.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Service Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Service Name",
        disableFilters: true,
        accessor: "service_id.name",
        Cell: tableInstance => (
          <span
            className={
              tableInstance.row.original.service_id !== null
                ? tableInstance.row.original.service_id.status === false ||
                  tableInstance.row.original.service_id.is_delete === true
                  ? "disablefiled text-capitalize"
                  : "text-capitalize"
                : ""
            }
          >
            {tableInstance.row.original.service_id === null
              ? "-"
              : tableInstance.row.original.service_id.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Staff Name"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Staff Names",
        disableFilters: true,
        accessor: "response_by.name",
        Cell: tableInstance => (
          <span
            className={
              tableInstance.row.original.response_by !== undefined
                ? tableInstance.row.original.response_by.status === false ||
                  tableInstance.row.original.response_by.is_delete === true
                  ? "disablefiled text-capitalize"
                  : "text-capitalize"
                : ""
            }
          >
            {/* {console.log(tableInstance.row.original.response_by,"check200")} */}
            {tableInstance.row.original.response_by === undefined
              ? "-"
              : tableInstance.row.original.response_by.name}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Comment"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "comment",
        disableFilters: true,
        accessor: "comment",
        Cell: tableInstance => (
          <span className={"text-capitalize"}>
            {console.log(tableInstance.row.values.comment, "check254")}
            {/* {console.log(tableInstance.row.original.response_by,"check200")} */}
            {tableInstance.row.values.comment
              ? tableInstance.row.values.comment
              : "-"}
          </span>
        )
      },
      {
        Header: tableInstance => {
          return (
            <HeaderComponent
              isSortedDesc={tableInstance.column.isSortedDesc}
              title="Status"
            />
          );
        },
        // Filter: FilterComponent,
        placeholder: "Status",
        disableFilters: true,
        accessor: "status",
        Cell: tableInstance => (
          <span className="text-capitalize">
            {tableInstance.row.original.status}
          </span>
        )
      }
    ],
    [requestData]
  );
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    headerGroups
    // pageCount,
    // gotoPage,
    // state: { pageIndex }
  } = useTable(
    {
      data: requestData,
      columns: columns,
      initialState: {
        pageSize: 10,
        pageIndex: 0
      }
    },
    useFilters,
    useSortBy,
    usePagination
  );
  return (
    <>
      <div className="p-2">
        <div className="row">
          <div className="col-xs-2 col-sm-2 col-md-2">
            <select
              className="detail-input-select custom-select w-100"
              value={venueFilter}
              onChange={e => {
                setVenueFilter(e.target.value);
                setPageCng(1);
                toggleRefresh(true);
              }}
            >
              <option value="">Select Venue</option>
              {/* {console.log(roomData, "roomData")} */}
              {venueData?.map(e => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
          &ensp;
          <div className="col-xs-2 col-sm-2 col-md-2">
            <select
              className="detail-input-select custom-select w-100"
              value={roomnameFilter}
              onChange={e => {
                setRoomNameFilter(e.target.value);
                setPageCng(1);
                toggleRefresh(true);
              }}
            >
              <option value="">Select Room</option>
              {/* {console.log(roomData, "roomData")} */}
              {roomData.map(e => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
          &ensp;
          <div className="col-xs-2 col-sm-2 col-md-2">
            <select
              className="detail-input-select custom-select w-100"
              value={servicenameFilter}
              onChange={e => {
                setServiceNameFliter(e.target.value);
                setPageCng(1);
                toggleRefresh(true);
              }}
            >
              <option value="">Select Services</option>
              {/* {console.log(staffData, "staffData")} */}
              {serviceData.map(e => (
                <option key={e.value} value={e.value}>
                  {e.label}
                  {/* {console.log(e, "check323")} */}
                </option>
              ))}
            </select>
          </div>
          &ensp;
          <div className="col-xs-2 col-sm-2 col-md-2">
            <select
              className="detail-input-select custom-select w-100"
              value={staffnameFilter}
              onChange={e => {
                setStaffNameFilter(e.target.value);
                setPageCng(1);
                toggleRefresh(true);
              }}
            >
              <option value="">Select Staff</option>
              {/* {console.log(staffData, "staffData")} */}
              {staffData.map(e => (
                <option key={e.value} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <ReactTableWrapper {...props}>
        <div className="table-container text-left overflow-auto mt-4 mx-2">
          <table
            border={1}
            className="custom-react-table-theme-class"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(header => (
                    <th
                      {...header.getHeaderProps(header.getSortByToggleProps())}
                    >
                      <div>{header.render("Header")}</div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td>
                    <Loader />
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                {/* {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(header => {
                                            return (
                                                <td
                                                    {...header.getHeaderProps(
                                                        header.getSortByToggleProps()
                                                    )}
                                                >
                                                    <div>
                                                        {header.canFilter ? header.render("Filter") : null}
                                                    </div>
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ))} */}
                {requestData.length > 0 ? (
                  page.map(row => {
                    // console.log(row, "row");
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="mt-4 text-left font-weight-bold">
                      <h5>No data found</h5>
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
        <div className="float-right">
          <Pagination
            // onPageChange={gotoPage}
            // pages={pageCount}
            // page={pageIndex}
            page={pageCng}
            count={pageCount}
            onChange={(e, val) => {
              setPageCng(val);
              toggleRefresh(true);
            }}
          />
        </div>
      </ReactTableWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  withRouter,
  // enhancer,
  connect(mapStateToProps, { success, error })
)(RequestList);
