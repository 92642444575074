import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import enhancer from "./enhancer/EditProfileEnhancer";

import NavigationActions from "redux/navigation/actions";
import {
  get_superadmin_profile,
  update_admin_profile
} from "services/adminServices";
import Loader from "components/Loader";

const { success, error } = NavigationActions;

const EditProfile = (props, { titleStyle }) => {
  // console.log(props, "props")
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount,
    token,
    setValues
  } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    await get_superadmin_profile(token).then(res => {
      // console.log("super admin response", res);
      if (res.success) {
        setValues({
          name: res?.data?.name,
          email: res?.data?.email,
          phone_number: res?.data?.phone_number
        });
      } else {
        error(res.message);
      }
    });
  };

  const submitHandler = async event => {
    event.preventDefault();
    var formData = new FormData();
    // console.log(values.file_image, "image")
    // formData.append("file_image", values.file_image);
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phone_number", values.phone_number);
    setLoading(true);
    await update_admin_profile(token, formData).then(res => {
      // console.log(values);
      // console.log("super admin response", res);
      if (res.success) {
        success(res.message);
        getProfile();
        setLoading(false);
      } else {
        error(res.message);
        setLoading(false);
      }
    });
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid">
      <div className="work-card py-2">
        {/* <div className="Work-header" style={titleStyle}> */}
        <h4>Profile Information</h4>
        {/* </div> */}
        <div className="work-body mt-4">
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    id="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    placeholder="Admin Name"
                  />
                  <Error field="name" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    id="email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                    placeholder="Admin Email"
                    disabled
                  />
                  <Error field="email" />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    id="phone_number"
                    onChange={handleChange}
                    value={values.phone_number}
                    onBlur={handleBlur}
                    placeholder="Admin Phone"
                  />
                  <Error field="phone_number" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <button
                  type="submit"
                  className="c-primary btn btn-secondary float-right"
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
        {loading && <Loader />}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken
  };
};

export default compose(
  // withRouter,
  enhancer,
  connect(mapStateToProps, { success, error })
)(EditProfile);
